import { getTimeAgoFormat, formatDateICU } from '@wix/communities-blog-client-common';
import { flowRight } from 'lodash';
import { connect } from '../components/runtime-context';
import { getIsICUEnabled, getLanguage } from '../store/basic-params/basic-params-selectors';
import withTranslate from './with-translate';

export default function withRelativeTimeFormatting(WrappedComponent) {
  const mapRuntimeToProps = (state, { t }) => {
    const language = getLanguage(state);
    const useICU = getIsICUEnabled(state);
    const formatRelativeTime = (time) => (useICU ? formatDateICU(time, language) : t(getTimeAgoFormat(time), { time }));

    return {
      formatRelativeTime,
    };
  };

  return flowRight(withTranslate, connect(mapRuntimeToProps))(WrappedComponent);
}
