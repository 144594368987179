import {
  CATEGORY_LABEL_TEXT_MOBILE_DESIGN_PARAMS,
  CATEGORY_LABEL_LAYOUTS,
  CATEGORY_LABEL_LAYOUT_PATH,
  CATEGORY_LABEL_TEXT_DESIGN_PARAMS,
  CATEGORY_LABEL_BUTTON_DESIGN_PARAMS,
  CATEGORY_LABEL_LAYOUT_MOBILE_PATH,
  CATEGORY_LABEL_BUTTON_MOBILE_DESIGN_PARAMS,
  CATEGORY_LABEL_MOBILE_DESIGN_TOGGLE,
} from '@wix/communities-blog-client-common';

import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { getIsMobileSettingEnabled } from '../../common/selectors/mobile-settings-selectors';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';

export const shouldUseMobileSettings = (state) =>
  getIsMobile(state) && getIsMobileSettingEnabled(state, CATEGORY_LABEL_MOBILE_DESIGN_TOGGLE.appSettingsPath);

export const getCategoryLabelLayout = (state) =>
  getAppSettingsValue({
    state,
    key: shouldUseMobileSettings(state) ? CATEGORY_LABEL_LAYOUT_MOBILE_PATH : CATEGORY_LABEL_LAYOUT_PATH,
    fallback: CATEGORY_LABEL_LAYOUTS.text,
  });

export const getIsCategoryLabelLayoutText = (state) => getCategoryLabelLayout(state) === CATEGORY_LABEL_LAYOUTS.text;

const getCategoryLabelParamsByDeviceType = (state) => {
  const useMobileSettings = shouldUseMobileSettings(state);
  const categoryLabelLayout = getCategoryLabelLayout(state);

  return {
    [CATEGORY_LABEL_LAYOUTS.text]: useMobileSettings
      ? CATEGORY_LABEL_TEXT_MOBILE_DESIGN_PARAMS
      : CATEGORY_LABEL_TEXT_DESIGN_PARAMS,
    [CATEGORY_LABEL_LAYOUTS.button]: useMobileSettings
      ? CATEGORY_LABEL_BUTTON_MOBILE_DESIGN_PARAMS
      : CATEGORY_LABEL_BUTTON_DESIGN_PARAMS,
  }[categoryLabelLayout];
};

export const getIsCategoryLabelUppercase = (state) => {
  const { uppercase } = getCategoryLabelParamsByDeviceType(state);

  return getAppSettingsValue({ state, key: uppercase.appSettingsPath, fallback: uppercase.defaultValue });
};
