import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import withRelativeTimeFormatting from '../../hoc/with-relative-time-formatting';

const TimeAgo = ({ className, time, timeFormatted, formatRelativeTime }) => {
  const timeAgo = timeFormatted || formatRelativeTime(time);

  return (
    <span title={timeAgo} className={classNames(className, 'time-ago')} data-hook="time-ago">
      {timeAgo}
    </span>
  );
};

TimeAgo.propTypes = {
  className: PropTypes.string,
  time: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  timeFormatted: PropTypes.string,
  formatRelativeTime: PropTypes.func.isRequired,
};

export default withRelativeTimeFormatting(TimeAgo);
